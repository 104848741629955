import { InfoPage } from "components/shared/info-page";
import React from "react";

const NotFoundPage = () => {
  return (
    <InfoPage>
      We apologize for the inconvenience, but the page you are trying to access
      could not be found. Please check the URL and try again.
    </InfoPage>
  );
};

export default NotFoundPage;
