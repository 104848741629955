import { Stack } from "flapstacks";
import { PREV_VALID_PAGE_STORAGE_KEY } from "hooks/use-track-page";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";
import { REDIRECT_STORAGE_KEY } from "../home-redirect";
import styles from "./index.module.css";

interface InfoPageProps {
  children: ReactNode;
}

export const InfoPage = ({ children }: InfoPageProps) => {
  const router = useRouter();
  useEffect(() => {
    window.localStorage.setItem(REDIRECT_STORAGE_KEY, "");
  }, []);
  return (
    <Stack
      gap="42px"
      as="main"
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={styles.Container}
    >
      <img src="/images/logo.png" alt="Bonver" width={132} height={35} />
      <h1 className={styles.Title}>{children}</h1>
      <button
        type="button"
        className={styles.Link}
        onClick={() => {
          const prevValidPage = window.localStorage.getItem(
            PREV_VALID_PAGE_STORAGE_KEY,
          );
          if (prevValidPage) {
            router.replace(prevValidPage);
          } else {
            router.back();
          }
        }}
      >
        Go back
      </button>
    </Stack>
  );
};
