import { useIsAuthenticated } from "@azure/msal-react";
import {
  REDIRECT_STORAGE_KEY,
  REDIRECT_TIMESTAMP_STORAGE_KEY,
} from "components/shared/home-redirect";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const PREV_VALID_PAGE_STORAGE_KEY = "prev-valid-page";

export const useTrackPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const router = useRouter();
  useEffect(() => {
    if (isAuthenticated && router.pathname !== "/") {
      window.localStorage.setItem(REDIRECT_STORAGE_KEY, router.asPath);
      window.localStorage.setItem(
        REDIRECT_TIMESTAMP_STORAGE_KEY,
        Date.now().toString(),
      );
    }
  }, [router, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      window.localStorage.setItem(PREV_VALID_PAGE_STORAGE_KEY, router.asPath);
    }
  }, [router.asPath, isAuthenticated]);
  return null;
};
